<template>
  <div id="app">
    <License/>
  </div>
</template>

<script>
import License from './components/License.vue'

export default {
  name: 'App',
  components: {
    License
  }
}
</script>
