<template>
  <div class="wrapper" :class="{noscroll: overlay}">
    <div class="overlay" v-if="overlay">
      <div class="overlay-header">
        <a class="close" @click="overlay=false">
          <font-awesome-icon icon="times"/>
        </a>
      </div>
      <div class="download">
        <vue-qrcode v-if="downloadUrl" :value="downloadUrl"/>
        <a :href="downloadUrl" v-if="downloadUrl" class="download-btn">
          <div class="download-icon"><font-awesome-icon icon="file-download"/></div>
          <div>Hlaða niður</div>
        </a>
      </div>
      <div class="instructions">
        Til hamingju, nýja kökuskírteinið þitt er tilbúið! Þú getur sett það í
        veskið með því að skanna QR kóðann eða með því að hala því niður, ef þú
        ert nú þegar í síma.
      </div>
    </div>
    <table class="license">
      <tr>
        <td colspan="2" rowspan="2">
          <table>
            <tr>
              <td>
                <img class="crest" src="../assets/coa.png">
              </td>
              <td>
                <div class="license-title">
                  <div>
                    Kökuskírteini (IS)
                  </div>
                  <div class="license-subtitle">
                    Cake license (IS)
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td class="right exp title">4b. gildir til</td>
      </tr>
      <tr>
        <td class="right top">
          <datepicker v-model="exp" :language="is" input-class="inp-exp" wrapper-class="datewrapper" format="dd-MM-yyyy"></datepicker>
        </td>
      </tr>
      <tr class="title-big">
        <td colspan="2">2. eiginnafn 1. kenninafn</td>
          <td rowspan="2" class="right id-photo">
            <div class="image-uploader">
              <img class="preview" :src="image">
              <image-uploader
                :debug="1"
                :quality="1"
                :autoRotate=false
                :preview=false
                outputFormat="string"
                :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                capture="environment"
                accept="image/*"
                @input="setImage"
                >
              </image-uploader>
            </div>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="name">
          <textarea type="text" class="inp-name" rows="2" v-model="name" placeholder="Nafn"/>
        </td>
        <td></td>
      </tr>
      <tr class="title">
        <td colspan="2">3. Fæðingardagur</td>
        <td class="right">4A. Útgáfudagur</td>
      </tr>
      <tr>
        <td colspan="2">{{ dob }}</td>
        <td class="right">
          <datepicker v-model="issued" :language="is" input-class="inp-issued" wrapper-class="datewrapper" format="dd-MM-yyyy"></datepicker>
        </td>
      </tr>
      <tr class="title">
        <td>4d. kennitala</td>
        <td class="center">5. númer</td>
        <td class="right">9. réttindaflokkar</td>
      </tr>
      <tr>
        <td valign="bottom"><input type="text" class="inp-kt" maxlength="10" v-model="kt" placeholder="0123456789"/></td>
        <td valign="bottom" class="center"><input type="text" maxlength="8" class="inp-sn" v-model="sn"></td>
        <td valign="bottom" class="right">
          <!--<input type="text" class="inp-categories" v-model="categories">-->
          <vue-tags-input
              v-model="tag"
              :tags="categories"
              @tags-changed="newTags => categories = newTags"
              :maxlength="1"
              placeholder="Flokkur"
              />
        </td>
      </tr>
      <tr>
        <td colspan="3" class="center barcode-row">
          <div class="barcode-wrapper">
              <div class="barcode">
                <img src="../assets/barcode.gif">
                <div>Í gildi - {{ current }}</div>
              </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="footer">
      <a class="submit" @click.prevent="genPkpass">
        Sækja Kökuskírteini
      </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ImageUploader from 'vue-image-upload-resize'
import Axios from 'axios'
import VueQrcode from 'vue-qrcode'
import Datepicker from 'vuejs-datepicker';
import {is} from 'vuejs-datepicker/dist/locale'
import VueTagsInput from '@johmun/vue-tags-input';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faFileDownload } from '@fortawesome/free-solid-svg-icons'

library.add(faTimes)
library.add(faFileDownload)

let BASE_URL = ''
if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://localhost:5000'
}

export default {
  name: 'License',
  data() {
    return {
      name: '',
      kt: '',
      issued: new Date(2011, 4, 30),
      sn: Math.floor((Math.random() * (100000))),
      exp: new Date(2055, 4, 30),
      hasImage: false,
      image: require('../assets/default-icon.png'),
      downloadUrl: '',
      is: is,
      tag: '',
      categories: [{text: 'B'}],
      overlay: false,
    }
  },
  computed: {
    dob() {
      if (this.kt.length < 10) {
        return '??-??-????'
      }
      let day = this.kt.substring(0, 2);
      let month = this.kt.substring(2, 4);
      let year = this.kt.substring(4, 6);
      let cent = this.kt.substring(9,10);
      let prefix = cent === '0' ? '20' : '1'+cent
      if (prefix) {
        year = prefix + year;
      } else {
        year = '????'
      }

      return `${day}-${month}-${year}`
    },
    current() {
      return moment().format('DD.MM.YYYY HH:mm:ss')
    }
  },
  methods: {
    setImage(file) {
      this.hasImage = true;
      this.image = file;
    },
    genPkpass () {
      this.overlay = true;
      let url = `${BASE_URL}/generate`
      Axios.post(url, {
        name: this.name,
        kt: this.kt,
        sn: this.sn,
        exp: moment(this.exp).format('DD-MM-YYYY'),
        issued: moment(this.issued).format('DD-MM-YYYY'),
        image: this.image,
        dob: this.dob,
        categories: this.categories.map(x => x.text).join(' '),
      }).then(response => {
        this.downloadUrl = response.data.url
      }).catch(console.error)
    },
  },
  watch: {
    kt(newval, oldval) {
      if (!/^\d*$/.test(newval)) {
        this.kt = oldval;
      }
    },
    sn(newval, oldval) {
      if (!/^\d*$/.test(newval)) {
        this.sn = oldval;
      }
    },
    tag(newval) {
      this.tag = newval.toUpperCase();
    }
  },
  components: {
    ImageUploader,
    VueQrcode,
    Datepicker,
    VueTagsInput,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
* {
  font-family: sans-serif;
  font-size: 16px;
}
body {
}
td {
  text-align: left;
}
.license {
  background-color: #1dcaf2;
  max-width: 800px;
  border-radius: 30px;
  padding: 40px 25px;
}
.title {
  font-weight: 800;
  text-transform: uppercase;
}
.title td {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
.title-big {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 30px;
  height: 50px;
}
.profile {
  max-height: 400px;
  max-width: 200px;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
  overflow-wrap: break-word;
}
.name {
  font-size: 30px;
  vertical-align: top;
}
.top {
  vertical-align: top;
}
.title td, .title-big td {
  padding-top: 30px;
}
.barcode-wrapper {
  display: flex;
  justify-content: center;
}
.barcode {
  margin-top: 120px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.barcode img {
  max-width: 280px;
}
.crest {
  max-width: 50px;
  width: auto;
}
.license-title {
  font-weight: 800;
  text-transform: None;
  margin-left: 10px;
  padding-top: 10px;
}
.license-subtitle {
  font-weight: 800;
  font-size: 14px;
}
.crest-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.exp {
  height: 30px;
}
input[type=text], textarea {
  background-color: transparent;
  border: 0px solid;
  border-width: 0 0 1px 0;
  resize: none;
}
input[type=text]:focus, textarea:focus {
  outline: none;
}
.inp-sn {
  width: 80px;
  text-align: center;
}
.inp-kt {
  width: 120px;
}
.inp-categories {
  width: 140px;
  text-align: right;
}
.inp-issued {
  width: 100px;
  text-align: right;
}
.inp-name {
  font-size: 28px;
  width: 220px;
}
.inp-exp {
  text-align: right;
  width: 110px;
  font-size: 20px;
}
.image-uploader {
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
}
.preview {
  max-width: 120px;
  max-height: 200px;
  margin-bottom: 10px;
  /* IE */
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);

  /* Chrome, Safari */
  -webkit-filter: grayscale(1);

  /* Firefox */
  filter: grayscale(1);
}
#fileInput {
  width: 120px;
}
.submit {
  margin-top: 10px;
  width: 100%;
  background: #1f88a2;
  text-align: center;
  padding: 12px 30px 10px 30px;
  cursor: pointer;
  font-weight: 600;
  color: white;
  border-radius: 5px;
}
.submit:hover {
  background: #21687b;
}
.footer {
  display: flex;
  align-items: flex-start;
}
.download {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-icon {
  font-size: 100px;
}
.download-btn {
  margin-left: 30px;
}
.datewrapper{
  display: flex;
  justify-content: flex-end;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #eeeeee;
  opacity: 0.8; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #eeeeee;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #eeeeee;
}
.vue-tags-input {
  background-color: transparent !important;
  max-width: 170px !important;
}
.vue-tags-input .ti-input {
  border: none !important;
}
.vue-tags-input .ti-tag {
  position: relative !important;
  background: #eee !important;
  color: #283944 !important;
}
.vue-tags-input input {
  min-width: 10px !important;
}
.vue-tags-input .ti-input {
  border-bottom: 1px solid black !important;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent !important;
  border: 1px solid #ebde6e !important;
  color: #ebde6e !important;
  margin-right: 4px !important;
  border-radius: 0px !important;
  font-size: 13px !important;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74 !important;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74 !important;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through !important;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform .2s;
  position: absolute;
  content: '';
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(200,200,200,0.9);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.overlay-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.overlay-header .close {
  font-size: 80px;
  padding: 10px 30px;
  cursor: pointer;
}
.overlay-header .close:hover {
  color: grey;
}
.instructions {
  margin-top: 30px;
  max-width: 400px;
  font-size: 18px;
  padding: 0 20px;
}
.noscroll {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  * {
    font-size: 13px;
  }
  .license {
    background-color: #1dcaf2;
    max-width: 800px;
    border-radius: 30px;
    padding: 20px 15px;
  }
  .license-subtitle {
    font-size: 12px;
  }
  .inp-sn {
    width: 20vw;
    text-align: center;
  }
  .inp-kt {
    width: 30vw;
  }
  .inp-categories {
    width: 140px;
    text-align: right;
  }
  .inp-issued {
    width: 100px;
    text-align: right;
  }
  .inp-name {
    font-size: 20px;
    width: 50vw;
  }
  .inp-exp {
    text-align: right;
    width: 100px;
    font-size: 18px;
  }
  .barcode {
    margin-top: 40px;
  }
  .barcode img {
    width: 70vw;
  }
  .preview {
    height: 100px;
  }
  #fileInput {
    width: 25vw;
  }
  .overlay-header .close {
    font-size: 50px;
  }
  .title td, .title-big td {
    padding-top: 10px;
  }
}
</style>
